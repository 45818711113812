<template>
  <div class="shops-page page">
    <BreadcrumbsComponent @back.prevent title="Адреса и филиалы" />
    <div class="container-padding">
      <div class="page__inner">
        <ShopsLocationComponent />
        <ShopsContactsComponent />
        <ShopsFeedbackComponent />
      </div>
    </div>
  </div>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import ShopsContactsComponent from "./components/ShopsContactsComponent.vue";
import ShopsLocationComponent from "./components/ShopsLocationComponent.vue";
import ShopsFeedbackComponent from "./components/ShopsFeedbackComponent.vue";
import SHOPS_PAGE from "@/graphql/pages/ShopsPage.graphql";

export default {
  name: "ShopsPage",
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: SHOPS_PAGE,
      })
      .then(({ data }) => {
        store.state.shops_page.shops = data.filials;
        store.state.global.info = data.info || {};
        // дефолтные данные
        store.state.global.header_categories = data.header_categories;
        store.state.global.footer_categories = data.footer_categories;
        store.state.global.categories = data.categories;
        store.state.global.brands = data.brands;
        store.state.global.info = data.info || {};
        store.state.global.pages = data.pages;
        store.state.global.branches = data.filials;
      });
  },
  metaInfo: {
    title: "Адреса и филиалы",
  },
  components: {
    BreadcrumbsComponent,
    ShopsFeedbackComponent,
    ShopsLocationComponent,
    ShopsContactsComponent,
  },
};
</script>
