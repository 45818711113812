var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"textarea",class:{
    'textarea--filled': !!_vm.value,
    'textarea--error': !!_vm.error,
    'textarea--icon-left': _vm.iconPosition === 'left',
    'textarea--icon-right': _vm.iconPosition === 'right',
    'textarea--modified': _vm.modified,
  }},[_c('span',{staticClass:"textarea__title"},[_c('span',[_vm._v(_vm._s(_vm.title))]),(_vm.required)?_c('span',{staticClass:"input__required"},[_vm._v("*")]):_vm._e()]),_c('span',{staticClass:"textarea__container"},[(_vm.iconPosition)?_c('button',{staticClass:"textarea__action",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('action')}}},[_vm._t("icon",function(){return [_c('RemixIconComponent',{attrs:{"category":"System","name":"search-2-line"}})]})],2):_vm._e(),_c('textarea',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],ref:"textarea",staticClass:"textarea__field",attrs:{"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"input":_vm.handleInput,"blur":function($event){return _vm.$emit('blur', $event)}}})]),(_vm.subtitle)?_c('span',{staticClass:"textarea__subtitle"},[_vm._v(_vm._s(_vm.subtitle))]):_vm._e(),(_vm.error)?_c('span',{staticClass:"textarea__error"},[_vm._v(_vm._s(_vm.error))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }