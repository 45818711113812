<template>
  <section class="shops-contacts">
    <h2>Контакты</h2>
    <div class="shops-contacts__types">
      <div v-for="(item, i) in types" :key="i" class="shops-contacts__type">
        <h4 class="shops-contacts__type-title">{{ item.title }}</h4>
        <SocialsComponent v-if="item.code === $store.state._types.SOCIAL" />
        <div v-else class="shops-contacts__list">
          <div v-for="(contact, index) in item.contacts" :key="index" class="shops-contacts__item">
            <ContactComponent :data="contact" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ContactComponent from "components/ContactComponent.vue";
import SocialsComponent from "components/SocialsComponent.vue";
export default {
  name: "ShopsContactsComponent",
  components: { SocialsComponent, ContactComponent },
  computed: {
    communications() {
      return this.$store.state.global.info.communications;
    },
    types() {
      if (this.communications && this.communications.length) {
        return [
          {
            title: "Номера телефонов",
            contacts: this.communications.filter((item) => this.$store.state._types.PHONE === item.type.code),
          },
          {
            title: "Электронная почта",
            contacts: this.communications.filter((item) => this.$store.state._types.EMAIL === item.type.code),
          },
          {
            title: "Соц. сети",
            code: this.$store.state._types.SOCIAL,
          },
        ];
      }
      return [];
    },
  },
};
</script>

<style lang="stylus">
.shops-contacts {
  &__types {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap var(--gap)
    +below(1100px) {
      grid-template-columns 1fr
      grid-gap 20px
    }
    +below(860px) {
      grid-gap 15px
    }
  }

  &__type {
    padding 15px 20px
    display flex
    flex-direction column
    background var(--white)
    border-radius var(--big-radius)
    +below(540px) {
      padding 15px
    }
  }
}
</style>
