<template>
  <section class="shops-location">
    <div class="shops-location__addresses-wrap">
      <h2 class="title">Филиалы</h2>
      <ul class="shops-location__addresses">
        <li class="shops-location__addresses-item" v-for="(item, i) in shops" :key="i">
          <a
            @click.prevent="setActive(item)"
            class="shops-location__address no-hover"
            :class="{ 'shops-location__address--active': isActive(item) }"
            href="#"
          >
            <span class="shops-location__address-title">{{ item.title }}</span>
            <span class="shops-location__address-value">{{ item.address }}</span>
            <div v-if="item.phone" class="shops-location__address-contacts">
              <ContactComponent :data="{ value: item.phone, type: { code: $store.state._types.PHONE } }" />
            </div>
          </a>
        </li>
      </ul>
    </div>
    <div class="shops-location__map">
      <MapComponent :addresses="shops" :active="active" />
    </div>
  </section>
</template>

<script>
import MapComponent from "components/MapComponent.vue";
import ContactComponent from "components/ContactComponent.vue";
export default {
  name: "ShopsLocationComponent",
  components: { ContactComponent, MapComponent },
  data() {
    return {
      active: undefined,
    };
  },
  computed: {
    shops() {
      return this.$store.state.shops_page.shops;
    },
  },
  methods: {
    setActive(item) {
      if (this.isActive(item)) {
        this.active = undefined;
      } else {
        this.active = item;
      }
    },
    isActive(item) {
      return JSON.stringify(item) === JSON.stringify(this.active);
    },
  },
};
</script>

<style lang="stylus">
.shops-location {
  display grid
  grid-template-columns 1fr 2fr
  grid-gap 25px
  padding 30px
  border-radius var(--big-radius)
  background var(--white)
  +below(990px) {
    grid-template-columns 1fr
  }
  +below(520px) {
    padding 15px
  }

  &__addresses-wrap {
    position relative

    &::after {
      content ""
      display block
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--white) 100%);
      height 48px
      position absolute
      left 0
      right 0
      bottom 0
      pointer-events none
    }

    .title {
      margin-bottom 30px
    }
  }

  &__addresses {
    display flex
    flex-direction column
    gap: 10px
    max-height 495px
    overflow auto
    padding-right 10px
    padding-bottom 48px
		scrollbar-width: thin

    &-item {
      flex-shrink 0
      display flex
      flex-direction column
    }
  }

  &__address {
    border: 2px solid var(--border-color);
    border-radius: var(--big-radius);
    padding 10px 15px
    display flex
    flex-direction column
    gap: 1px

    &:hover {
      border-color var(--main)
    }

    &--active {
      background var(--gray)
    }

    &-title {
      font-weight: 700;
      font-size: 1.125em
      line-height: 1.55
    }

    &-value {
      color var(--dark-light)
    }
  }

  &__map {
    .ymap-container {
      height 563px
      +below(990px) {
        height 500px
      }
      +below(860px) {
        height 420px
      }
    }
  }
}
</style>
