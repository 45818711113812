<template>
  <section class="shops-feedback">
    <div class="shops-feedback__left">
      <h2 class="title">Всегда рады помочь</h2>
      <span class="shops-feedback__description">
        Если у вас есть вопрос или пожелание, пожалуйста напишите. Мы ответим на любые вопросы и постараемся
        решить проблему.
      </span>
    </div>
    <form @submit.prevent="submit" class="shops-feedback__form">
      <InputComponent v-model="form.title.value" :error="form.title.error" title="Имя" modified required />
      <InputComponent
        v-model="form.email.value"
        :error="form.email.error"
        title="Электронная почта"
        modified
        required
      />
      <TextareaComponent
        v-model="form.comment.value"
        :error="form.comment.error"
        title="Комментарий или вопрос"
        modified
        required
      />
      <div class="shops-feedback__bottom">
        <button type="submit" class="btn btn--md btn--red">
          <IconComponent v-if="loading" name="loading" />
          <template v-else>Оставить заявку</template>
        </button>
        <span class="shops-feedback__terms">
          Нажимая на кнопку, вы соглашаетесь с <a href="/files/terms.pdf">условиями использования</a>
        </span>
      </div>
    </form>
  </section>
</template>

<script>
import InputComponent from "components/inputs/InputComponent.vue";
import IconComponent from "components/IconComponent.vue";
import TextareaComponent from "components/inputs/TextareaComponent.vue";
import CLAIM_CREATE from "@/graphql/mutations/ClaimCreate.graphql";

export default {
  name: "ShopsFeedbackComponent",
  components: { TextareaComponent, IconComponent, InputComponent },
  data() {
    return {
      loading: false,
      form: {
        title: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        email: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
        comment: {
          value: undefined,
          message: undefined,
          error: undefined,
        },
      },
    };
  },
  methods: {
    submit() {
      if (!this.loading) {
        this.loading = true;
        this.$apollo.provider.defaultClient
          .mutate({
            mutation: CLAIM_CREATE,
            variables: {
              title: this.form.title.value,
              email: this.form.email.value,
              comment: this.form.comment.value,
              type: this.$store.state._types.HAPPY,
            },
            context: {
              headers: {
                Authorization: "Bearer " + this.$store.state.apollo_token,
              },
            },
          })
          .then(({ data }) => {
            if (data && data.ClaimCreate) {
              this.$notify({
                title: "Отправлено",
                text: data.ClaimCreate.message,
                duration: 6000,
                type: "success",
              });
            }
            this.loading = false;
            this.resetForm();
          })
          .catch(({ graphQLErrors }) => {
            this.loading = false;
            this.parseGqlErrors(graphQLErrors);
          });
      }
    },
    resetForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = undefined;
      });
    },
    parseGqlErrors(graphQLErrors) {
      graphQLErrors.forEach((err) => {
        if (err.extensions.category === "validation") {
          Object.keys(err.extensions.validation).forEach((key) => {
            if (this.form[key]) {
              this.form[key].error = err.extensions.validation[key][0];
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="stylus">
.shops-feedback {
  display grid
  grid-template-columns minmax(400px, 440px) 1fr
  grid-gap var(--gap)
  padding 30px
  border-radius var(--big-radius)
  background var(--white)
  +below(1150px) {
    grid-template-columns 1fr
    grid-gap 15px
  }
  +below(540px) {
    padding 15px
  }

  .title {
    margin-bottom 20px
  }

  &__description {
    color: var(--dark-light)
    font-size: 1.125em
    line-height: 28px;
    +below(540px) {
      font-size 1em
      line-height: 22px;
    }
  }

  &__form {
    display grid
    grid-gap 20px
    grid-template-columns 1fr 1fr
    +below(620px) {
      grid-template-columns 1fr
      grid-gap 15px
    }

    > *:nth-child(n+3) {
      +above(621px) {
        grid-column: auto / span 2;
      }
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns 1fr 1fr
    align-items center
    grid-gap: 20px
    max-width 612px
    +below(540px) {
      grid-template-columns 1fr
      grid-gap 15px
    }
  }

  &__terms {
    font-size: 0.75em
    line-height: 14px;
    color: var(--dark-light)

    a {
      border-bottom 1px solid
    }
  }
}
</style>
